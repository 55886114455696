<template>
  <div class="w-full px-8">
    <ListMenuBar
      search-placeholder-label="Search subscription"
      main-context-button-label="New Subscription"
      :main-context-button-target="{
        name: 'route.dashboard.subscription.editor.create'
      }"
      use-main-context-button
    ></ListMenuBar>
    <div class="px-12 py-12 my-8 bg-white rounded">
      <ListTableSimple
        :items="items"
        :columns="columns"
        :on-row-click-target="{
          name: 'route.dashboard.subscription.overview',
          targetColumn: 'id'
        }"
      ></ListTableSimple>
    </div>
  </div>
</template>

<script>
import ListMenuBar from '@/components/List/ListMenuBar.vue'
import ListTableSimple from '@/components/List/ListTableSimple.vue'

export default {
  components: {
    ListMenuBar: ListMenuBar,
    ListTableSimple: ListTableSimple
  },
  data () {
    return {
      columns: [
        {
          label: 'Subscription',
          name: 'subscription'
        },
        {
          label: 'Total Subscriber',
          name: 'totalSubscriber'
        },
        {
          label: 'Date Create',
          name: 'dateCreate'
        },
        {
          label: 'Status',
          name: 'status'
        }
      ],
      items: [
        {
          id: 0,
          subscription: 'Monthly Pack',
          totalSubscriber: 100,
          dateCreate: '11/24/2021',
          status: 'Active'
        },
        {
          id: 0,
          subscription: 'Monthly Pack',
          totalSubscriber: 100,
          dateCreate: '11/24/2021',
          status: 'Active'
        },
        {
          id: 0,
          subscription: 'Monthly Pack',
          totalSubscriber: 100,
          dateCreate: '11/24/2021',
          status: 'Active'
        },
        {
          id: 0,
          subscription: 'Monthly Pack',
          totalSubscriber: 100,
          dateCreate: '11/24/2021',
          status: 'Active'
        },
        {
          id: 0,
          subscription: 'Monthly Pack',
          totalSubscriber: 100,
          dateCreate: '11/24/2021',
          status: 'Active'
        }
      ]
    }
  }
}
</script>
